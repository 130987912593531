import logo from './logo.svg';
import './App.css';
import { Outlet, Route, Routes, NavLink   } from 'react-router-dom';
import { IoMenu } from "react-icons/io5";
import HomePage from './HomePage';
import Experiences from './Experiences';
import Skills from './Skills';
import Projects from './Projects';
import Contact from './Contact';
import ToS from './ToS';
import PrivacyPolicy from './PrivacyPolicy';
import { useState } from 'react';
import { click } from '@testing-library/user-event/dist/click';

import ReactDOM from 'react-dom';


const NavBar = () =>
{
  const [show, setShow] = useState(false);
  const classLink = show ? 'show' : undefined;
  const hide = () => 
    {
      setShow(!show); 
      document.getElementById('reverseAnimation').beginElement();
    };
  return (
    <header>
      <span className={'navbar_shadow ' + (show ? 'show_shadow' : undefined)}></span>
      <div className='hamburger_btn'><button onClick={() => setShow(!show) }>
      
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="20" height="20" version="1.1">
  <g transform="translate(67.7335 67.7335)">
  <g transform="translate(-17.7335 -17.7335)">
   <g transform="rotate(0)">
    <g transform="scale(1 1)">
     <g transform="skewX(0) skewY(0)">
      <g opacity="1" transform="translate(-67.7335 -67.7335)">
       <g>
        <g transform="translate(34.3096 33.5487)">
         <g>
          <animateTransform values="0 0;0 0;9.9712 0;10 0" attributeName="transform" type="translate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;0.998184;1" keySplines="0 0 1 1;4.85877e-05 0.906745 0.890797 1;0.34782 0.333333 0.681213 0.666667" begin="startAnimation.begin"/>
          <animateTransform values="10 0;10 0;0 0" attributeName="transform" type="translate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;1" keySplines="0 0 1 1;4.87805e-05 0.488166 0.75 1" begin="reverseAnimation.begin"/>
          
          <g>
            <animateTransform values="0 0;0 0;0 10" attributeName="transform" type="translate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;1" keySplines="0 0 1 1;4.87805e-05 0.967019 0.861707 0.995554" begin="startAnimation.begin"/>
            <animateTransform values="0 10;0 10;0 0" attributeName="transform" type="translate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;1" keySplines="0 0 1 1;0 0.507692 0.75 1" begin="reverseAnimation.begin"/>
           
           <g>
            <animateTransform values="0;0;45;45" attributeName="transform" type="rotate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;1;1" keySplines="0 0 1 1;0 1 0.89039 0.989324;0.333333 0.333333 0.666667 0.666667" begin="startAnimation.begin"/>
            <animateTransform values="45;45;-1.88755;0" attributeName="transform" type="rotate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;0.868346;1" keySplines="0 0 1 1;0.00101375 0.517359 0.638089 1;0.390756 3.48323e-16 0.732877 0.317267" begin="reverseAnimation.begin"/>
            <g transform="scale(1 1)">
             <g transform="skewX(0) skewY(0)">
             <g opacity="1" transform="translate(-34.3096 -33.5487)">
               <path fillerule="evenodd" stroke="rgba(255, 255, 255, 1)" fill="rgba(253, 92, 0, 1)" strokeLinecap="round" strokeLinejoin="miter" strokeWidth="15" d="M33.867 33.867C33.867 33.867 101.6 33.867 101.6 33.867"/>
               </g>
             </g>
            </g>
           </g>
          </g>
         </g>
        </g>
        <g transform="translate(67.7335 67.733)">
         <g transform="translate(0 0)">
          <g transform="rotate(0)">
           <g transform="scale(1 1)">
            <g transform="skewX(0) skewY(0)">
             <g opacity="1" transform="translate(-67.7335 -67.733)">
             <path stroke="rgba(255, 255, 255, 1)" fillerule="evenodd" fill="rgba(255, 255, 255, 1)" strokeLinecap="round" strokeLinejoin="miter" strokeWidth="15" d="M33.867 67.733C33.867 67.733 101.6 67.733 101.6 67.733">
               <animate values="rgba(255, 255, 255, 1);rgba(255, 255, 255, 0.718349);rgba(255, 255, 255, 0.608255);rgba(255, 255, 255, 0.526696);rgba(255, 255, 255, 0.460029);rgba(255, 255, 255, 0.403052);rgba(255, 255, 255, 0.353033);rgba(255, 255, 255, 0.30837);rgba(255, 255, 255, 0.268055);rgba(255, 255, 255, 0.231388);rgba(255, 255, 255, 0.197848);rgba(255, 255, 255, 0.167086);rgba(255, 255, 255, 0.138857);rgba(255, 255, 255, 0.112947);rgba(255, 255, 255, 0.0892653);rgba(255, 255, 255, 0.0677348);rgba(255, 255, 255, 0.0483864);rgba(255, 255, 255, 0.0313725);rgba(255, 255, 255, 0.0169528);rgba(255, 255, 255, 0.00585946);rgba(255, 255, 255, 0)" attributeName="stroke" calcMode="linear" dur="0.666667s" fill="freeze" keyTimes="0;0.05;0.1;0.15;0.2;0.25;0.3;0.35;0.4;0.45;0.5;0.55;0.6;0.65;0.7;0.75;0.8;0.85;0.9;0.95;1"  begin="startAnimation.begin"/>
               <animate values="rgba(255, 255, 255, 0);rgba(255, 255, 255, 0.257984);rgba(255, 255, 255, 0.364889);rgba(255, 255, 255, 0.445914);rgba(255, 255, 255, 0.513298);rgba(255, 255, 255, 0.571801);rgba(255, 255, 255, 0.623819);rgba(255, 255, 255, 0.670787);rgba(255, 255, 255, 0.713619);rgba(255, 255, 255, 0.752941);rgba(255, 255, 255, 0.789166);rgba(255, 255, 255, 0.822599);rgba(255, 255, 255, 0.853437);rgba(255, 255, 255, 0.881788);rgba(255, 255, 255, 0.907713);rgba(255, 255, 255, 0.931212);rgba(255, 255, 255, 0.952117);rgba(255, 255, 255, 0.970214);rgba(255, 255, 255, 0.985);rgba(255, 255, 255, 0.99559);rgba(255, 255, 255, 1)" attributeName="stroke" calcMode="linear" dur="0.666667s" fill="freeze" keyTimes="0;0.05;0.1;0.15;0.2;0.25;0.3;0.35;0.4;0.45;0.5;0.55;0.6;0.65;0.7;0.75;0.8;0.85;0.9;0.95;1" begin="reverseAnimation.begin"/>
              </path>
             </g>
            </g>
           </g>
          </g>
         </g>
        </g>
        <g transform="translate(34.3096 101.282)">
         <g>
          <animateTransform values="0 0;0 0;10 0" attributeName="transform" type="translate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;1" keySplines="0 0 1 1;4.87805e-05 0.982067 0.902683 0.995122" begin="startAnimation.begin"/>
          <animateTransform values="10 0;10 0;0 0" attributeName="transform" type="translate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;1" keySplines="0 0 1 1;0.00107317 0.519408 0.75 1" begin="reverseAnimation.begin"/>
         
          
          <g>
           <animateTransform values="0 0;0 0;0 -10" attributeName="transform" type="translate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;1" keySplines="0 0 1 1;4.87805e-05 0.798393 0.885268 0.994792"  begin="startAnimation.begin"/>
           <animateTransform values="0 -10;0 -10;0 0" attributeName="transform" type="translate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;1" keySplines="0 0 1 1;0.00107317 0.515503 0.75 1"  begin="reverseAnimation.begin"/>
          
           
           <g>
            <animateTransform values="0;0;-45.2994" attributeName="transform" type="rotate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;1" keySplines="0 0 1 1;0 0.805964 0.814585 0.988162" begin="startAnimation.begin"/>
            <animateTransform values="-45.2994;-45.2994;0" attributeName="transform" type="rotate" calcMode="spline" dur="0.666667s" fill="freeze" keyTimes="0;0;1" keySplines="0 0 1 1;0.00107317 0.503623 0.75 1"  begin="reverseAnimation.begin"/>
           
            
            <g transform="scale(1 1)">
             <g transform="skewX(0) skewY(0)">
              <g opacity="1" transform="translate(-34.3096 -101.282)">
              <path fillerule="evenodd" stroke="rgba(255, 255, 255, 1)" fill="rgba(253, 92, 0, 1)" strokeLinecap="round" strokeLinejoin="miter" strokeWidth="15" d="M101.6 101.6C101.6 101.6 33.867 101.6 33.867 101.6"/>
              </g>
             </g>
            </g>
           </g>
          </g>
         </g>
        </g>
       </g>
      </g>
     </g>
    </g>
   </g>
  </g>
 </g>
 <defs/>
 {/* CONTROLES !!!!!!!! */}
 <circle cx="100" cy="100" r="100" fillOpacity="0">
      <animate dur="0.01s" id="startAnimation" attributeName="r" values="100; 0" fill="freeze" begin="click" />
      <animate dur="0.01s" attributeName="r" values="0; 100" fill="freeze" begin="reverseAnimation.end" />
    </circle>
    <circle cx="100" cy="100" r="0" fillOpacity="0">
      <animate dur="0.001s" id="reverseAnimation" attributeName="r" values="100; 0" fill="freeze" begin="click" />
      <animate dur="0.001s" attributeName="r" values="0; 100" begin="startAnimation.end"  fill="freeze"  />
    </circle>
</svg>
</button>
</div>   
      <nav className={"navbar "+ (show ? 'show' : undefined)}>
        <NavLink to='/' onClick={() => hide()}> Home</NavLink>
        <NavLink to="/skills" onClick={() => hide()}>Technical skills</NavLink>
        <NavLink to="/projects" onClick={() => hide()}>Projects</NavLink>
        <NavLink to="/experiences" onClick={() => hide()}>Professional Experience</NavLink>
        <NavLink to="/contact" onClick={() => hide()}>Contact</NavLink>
      </nav>
      <Outlet />
    </header>
  );
}

function App() {
  const click_effect = function(e) {
    var d=document.createElement("div");
    d.className="click_effect";
    d.style.top=e.clientY+"px";
    d.style.left=e.clientX+"px";
    d.style.transform="rotate("+Math.random()+"turn)";
    document.body.appendChild(d);
    d.addEventListener('animationend',function(){d.parentElement.removeChild(d);}.bind(this));
  }
  document.onmousedown = click_effect;
  return (
        <Routes >
          <Route path="/" element={<NavBar />}>
            <Route index element={<HomePage />} />
            <Route path="skills" element={<Skills />} />
            <Route path="projects" element={<Projects />} />
            <Route path="experiences" element={<Experiences />} />
            <Route path="contact" element={<Contact />} />
            <Route path="ToS" element={<ToS />} />
            <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          </Route>
        </Routes>
  );
}

export default App;
