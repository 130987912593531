import './Experiences.css';

const JobsDescription = [
    ["2023 - PRESENT", "IT OFFICER  - ANSGAR BERGMANN CONSULTING", "Software in Python for data mining on a large number of voluminous word documents.", "Using AI to find prior art documents closest to given documents.",
        "Using AI for data mining in non-searchable pdf documents.", "IT-support."],
    ["2021 - PRESENT", "FREELANCER “DREALIGHT”", "Android game in Java and openGL 2.0 published on the Google play store.",
        "Games in Unity published on the Google play store.", "Several MVPs with Voodoo and Monday Off.", "Unity shaders sold on Fiverr.",
        "Unity c# scripts for Hypercasual games sold on Fiverr."],
    ["2016", "VR DEVELOPER – INTERNSHIP AT “VALENTINSTUDIO”", "Development of immersive real estate tours in virtual reality Unity and Unreal Engine.",
        "Deployment on HTC Vive and Gear VR.", "Independent work on all aspects of programming, deployment, and optimization."],
    ["2016", "Master's Degree in Computer Science: “Multimedia Design and Integration – Programming and Development” at Université Lumière Lyon 2"]
]

function Experience({index})
{
    const list = [];
    for (let i = 2; i < JobsDescription[index].length; i++) {
        list.push(<li key= {(i + 1) + ((index + 1) * 10)}>{JobsDescription[index][i]}</li>)
    }
    return(
        <div className="experience">
            <h3>{JobsDescription[index][0]}</h3>
            <div className='right'>
                <h2>{JobsDescription[index][1]}</h2>
                <ul>
                    {list}
                </ul>
            </div>
        </div>
    );
}
function Experiences()
{
    
    const list = [];
    for (let i = 0; i < JobsDescription.length; i++) {
        list.push(<Experience index={i} key={i}/>);
    }
    return(
        <div className="experiences content">
            {list}
        </div>
    );
}

export default Experiences;