
import './HomePage.css';
import { SiUnity, SiCsharp, SiVisualstudiocode, SiAndroid  } from "react-icons/si";
import { FaGooglePlay, FaTwitter, FaLinkedin, FaSteam, FaTiktok, FaPlus  } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import { Outlet, Route, Routes, NavLink   } from 'react-router-dom';
import temple from "./imgs/Blight Bubbles/temple.png";
import garden1 from "./imgs/Word garden/Scrn1.jpg";
import blockbreaker1 from "./imgs/BlockBreaker3D/Scrn4.jpg";
import drawshuriken1 from "./imgs/BattlerDraw/New Screenshot2.jpg";

function HomePage()
{
  return (
    <div className='Home content'>
      <svg width="0" height="0">
        <linearGradient id="blue-gradient" x1="100%" y1="50%" x2="0%" y2="50%">
            <stop stopColor="#7a6ded" offset="0%">
            <animate attributeName="stop-color"
                values="red;purple;blue;green;yellow;orange;red;" dur="20s" repeatCount="indefinite">
            </animate>
            </stop>
            <stop stopColor="#591885" offset="100%">
                
            <animate attributeName="stop-color"
                values="purple;blue;green;yellow;orange;red;purple;" dur="20s" repeatCount="indefinite">
            </animate>
            </stop>
        </linearGradient>
        </svg>
        <svg width="0" height="0">
                <radialGradient id="icon-gradient" cx="106.57" cy="90.226" r="50" gradientTransform="matrix(2.2959 -1.3255 1.5786 2.7343 -280.54 -23.554)" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#f5f5f5" offset="0"/>
                    <stop stopColor="#6f6f6f" offset=".75"/>
                    <stop stopColor="#f5f5f5" offset="1"/>
                </radialGradient>
                <radialGradient id="icon-gradient2" cx="56.57" cy="10.226" r="300" gradientTransform="matrix(2.2959 -1.3255 1.5786 2.7343 -280.54 -23.554)" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#f5f5f5" offset="0"/>
                    <stop stopColor="#6f6f6f" offset=".75"/>
                    <stop stopColor="#f5f5f5" offset="1"/>
                </radialGradient>
            </svg>
      <div className='intro'>
        <div className='presentation'>
          <h1>Sergio Baudino M. Sc.</h1>
          <h2>Unity developer</h2>
          <h3>Main technologies</h3>
          <ul>
            <li><a href="https://unity.com/"><SiUnity  style={{ fill: "url(#icon-gradient)" }} /></a></li>
            <li><a href="https://dotnet.microsoft.com/en-us/languages/csharp"><SiCsharp  style={{ fill: "url(#icon-gradient)" }} /></a></li>
            <li><a href="https://code.visualstudio.com/"><SiVisualstudiocode  style={{ fill: "url(#icon-gradient)" }} /></a></li>
            <li><a href="https://www.android.com/"><SiAndroid  style={{ fill: "url(#icon-gradient)" }} /></a></li>
            <li><NavLink className="more" to="/skills">More...</NavLink></li>
          </ul>
        </div>
        <div className='description'>
          <h3>About me</h3>
          <p>I have been passionate about games and how to make them for as long as I remember.<br/>
          I started learning programming at 13 years old with this goal in mind.<br/>
          During my Computer Science Master's degree specialized in video games in 2015-2016,<br/>I discovered Unity which I have been using since.<br/>
          My goal is to create flow-inducing, attractive, polished experiences.<br/>
          Explore my projects, and don’t hesitate to contact me for any collaboration or question.<br/></p>
        </div>
      </div>

      <div className='featured_projects'>
        <h2>Featured Projects</h2>
        <ul>
          <li><NavLink to="/projects" state={{ from: "4" }}><img src={temple} alt="blight bubbles scrnsht"/></NavLink></li>
          <li><NavLink to="/projects" state={{ from: "0" }}><img src={garden1} alt="word garden scrnsht"/></NavLink></li>
          <li><NavLink to="/projects" state={{ from: "1" }}><img src={blockbreaker1} alt="block breaker 3D scrnsht"/></NavLink></li>
          <li><NavLink to="/projects" state={{ from: "2" }}><img src={drawshuriken1} alt="draw shuriken scrnsht"/></NavLink></li>
          <li><div className='plus'><NavLink to="/projects" ><FaPlus style={{ fill: "url(#blue-gradient)" }}/></NavLink></div></li>
        </ul>
      </div>

      <div className='reviews_section'> 
        <h2>Reviews</h2>
        <div className='reviews'> 
          <div className='review'>
            <h3>dani217 - Fiverr</h3>
            <p>Excellent experience 100% satisfied, the game I received was just as I ordered, good communication and high quality delivery, I received progress on the project in its development, highly recommended, I hope to continue working together.</p>
          </div>
          <div className='review'>
            <h3>dualcarbonltd - Fiverr</h3>
            <p>Excellent work, created custom shader just as described and very quickly.</p>
          </div>
        </div>
      </div>
      <div className='quick_contact'>
        <a href="mailto:sergio@drealight.com"><MdMailOutline  style={{ fill: "url(#icon-gradient)" }} /></a>
        <a href="https://play.google.com/store/apps/developer?id=drealight"><FaGooglePlay  style={{ fill: "url(#icon-gradient2)" }} /></a>
        <a href="https://x.com/blightbubbles"><FaTwitter  style={{ fill: "url(#icon-gradient2)" }} /></a> 
        <a href="https://de.linkedin.com/in/sergio-baudino-6a2874140"><FaLinkedin  style={{ fill: "url(#icon-gradient2)" }} /></a> 
        <a href="https://store.steampowered.com/app/2507670/Blight_Bubbles/"><FaSteam  style={{ fill: "url(#icon-gradient2)" }} /></a> 
        <a href="https://www.tiktok.com/@drealightgames"><FaTiktok  style={{ fill: "url(#icon-gradient2)" }} /></a>
      </div>
    </div>
  );
}
export default HomePage;