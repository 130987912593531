
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Projects.css';
import { Suspense } from 'react';
import { useLocation } from 'react-router-dom'
import { useEffect, useState, useRef } from "react";
import { FaGooglePlay, FaSteam } from "react-icons/fa";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import iconwg from "./imgs/Word garden/Word Garden.png";
import scrn0wg from "./imgs/Word garden/Scrn0.jpg";
import scrn1wg from "./imgs/Word garden/Scrn1.jpg";
import scrn2wg from "./imgs/Word garden/Scrn2.jpg";
import iconbb from "./imgs/BlockBreaker3D/Block Breaker 3D.png";
import scrn0bb from "./imgs/BlockBreaker3D/Scrn0.jpg";
import scrn1bb from "./imgs/BlockBreaker3D/Scrn4.jpg";
import scrn2bb from "./imgs/BlockBreaker3D/Scrn-4.jpg";
import iconds from "./imgs/BattlerDraw/Draw Shuriken.png";
import screen0ds from "./imgs/BattlerDraw/New Screenshot1.jpg";
import screen1ds from "./imgs/BattlerDraw/New Screenshot2.jpg";
import screen2ds from "./imgs/BattlerDraw/New Screenshot3.jpg";
import iconblockaze from "./imgs/Blockaze/BlockazeIcon.png";
import scrn0blockaze from "./imgs/Blockaze/1.png";
import scrn1blockaze from "./imgs/Blockaze/2.png";
import scrn2blockaze from "./imgs/Blockaze/6.png";
import iconblb from "./imgs/Blight Bubbles/icon.jpg";
import screen0blb from "./imgs/Blight Bubbles/cavern.png";
import screen1blb from "./imgs/Blight Bubbles/magical.png";
import screen2blb from "./imgs/Blight Bubbles/temple.png";
import {TikTokEmbed} from 'react-social-media-embed';
import { YouTubeEmbed } from 'react-social-media-embed';
import React, { Component } from "react";
import Slider from "react-slick";
import { Virtuoso } from 'react-virtuoso'

const ProjectDescription = [[iconwg, "Word Garden", "https://play.google.com/store/apps/details?id=com.drealight.wordgarden", 
<FaGooglePlay />, 
"Word garden is a relaxing word game where players must find words to grow flowers around the letters used, and fill the garden with grown flowers to progress to the next level. Developped with Unity and C#, this game has bright graphism, relaxing and rewarding animations/effects and an opportunity to cultivate your mind for a soothing experience.",
<div style={{ display: 'flex', justifyContent: 'center'}}><Suspense><TikTokEmbed url="https://www.tiktok.com/@drealightgames/video/7231489965195152667" width={325} /></Suspense></div>,
 scrn0wg,
 scrn1wg,
 scrn2wg,
 "Relaxing 2D arts and animations&One hand intuitive control&Word search&Longer word make flower around grow faster to encourage the player to challenge themselves&Hint functionality to avoid frustration",
 "Unity: Game engine used&C#: Main programming language&Game Analytics: Analytics tool used to gain information such as which level player stopped playing, bug, etc...&Inkscape: Main 2D art program",
 "As the main developer, I designed and programmed the game mechanics and created the art. I also thoroughly tested the game for a bug-less, responsive experience"
],
[iconbb, "Block Breaker 3D", "https://play.google.com/store/apps/details?id=com.drealight.blockbreaker3D", 
    <FaGooglePlay />, 
    "Block breaker 3D is an exciting brick breaker game where players must clear all bricks to win each level. Using Unity and C#, this game has “blocky” graphics and rewarding animations/effects.",
    <div style={{ display: 'flex', justifyContent: 'center' }}><Suspense><TikTokEmbed url="https://www.tiktok.com/@drealightgames/video/7230049525279296794" width={325}/></Suspense></div>,
     scrn0bb,
     scrn1bb,
     scrn2bb,
     "Brick Breaker mechanics and physics&One hand intuitive control&3D Layers: When bottom blocks are destroyed, connected higher layers fall down&Combo functionality: after a certain number of hits with one shot, the damage is doubled&Upgrades between levels&Level Editor made with Unity",
      "Unity: Game engine used&C#: Main programming language&Game Analytics: Analytics tool used to gain information such as which level player stopped playing, bug, etc...&Inkscape: Main 2D art program&Blender: Main 3D art program",
      "As the main developer, I designed and programmed the game mechanics and created the art. I also thoroughly tested the game for a bug-less, responsive experience"
    ],
[iconds, "Draw Shuriken", "https://play.google.com/store/apps/details?id=com.Drealight.DrawShuriken", 
    <FaGooglePlay />, 
        "Draw shuriken is a battler game, where players must clear the waves of enemies by drawing the path of the weapons they throw at them. Using Unity and C#, this game has simple distinct 3D graphics, relaxing and rewarding animations/effects.",
        <div style={{ display: 'flex', justifyContent: 'center' }}><Suspense><TikTokEmbed url="https://www.tiktok.com/@drealightgames/video/7230701830232165659" height="550px"/></Suspense></div>,
        screen0ds,
        screen1ds,
        screen2ds,
        "The weapon follow the path drawn by the player&Simple and distinct 2D arts and animations&One hand intuitive control&Experience and upgrade system",
        "Unity: Game engine used&C#: Main programming language&Game Analytics: Analytics tool used to gain information such as which level player stopped playing, bug, etc...&Inkscape: Main 2D art program&Blender: Main 3D art program",
        "As the main developer, I designed and programmed the game mechanics and created the art. I also thoroughly tested the game for a bug-less, responsive experience"
    ],
    [iconblockaze, "Blockaze", "https://play.google.com/store/apps/details?id=com.drealight.blockaze", 
        <FaGooglePlay />, 
            "Blockaze is a challenging puzzle game. Move blocks to their goals, changing shapes and colors, using the right elements. Using Java and OpenGL ES 2.0, this game has bright graphics, relaxing and rewarding animations/effects while being lightweight.",
            <div style={{ display: 'flex', justifyContent: 'center' }}><Suspense><YouTubeEmbed url="https://www.youtube.com/watch?v=rYwAr97lwfM" width={325} height={220} /></Suspense></div>,
            scrn0blockaze,
            scrn1blockaze,
            scrn2blockaze,
            "Relaxing 2D art and animations&One hand intuitive controls&Grid based movement&Diverse gameplay elements such as blocks rotations, color changes, teleportation, and more&Hint functionality to avoid frustration&Solver made with python to find the best solutions&Stars depending on the number of moves&Fully functional game including animations, particle systems, text display, level system, real time lighting with normal maps etc... all made from scratch with OpenGL and Java.",
            "Java: Main programming language&OpenGL ES 2.0: Main graphics API&IAP and ads from Google&Inkscape: Main 2D art program&Ableton: Main music/SFX program",
            "As the main developer, I designed, programmed the game mechanics, created the 2D art, the sounds and the music. I also thoroughly tested the game for a bug-less, responsive experience."
        ],
    [iconblb, "Blight Bubbles", "https://play.google.com/store/apps/details?id=com.drealight.blockaze", 
        <FaSteam />, 
            "Blight Bubbles is a WIP FPS metroidvania where players must jump, fight, craft potions and solve puzzles using a gun that shoots magical bubbles. Unlockable platforming abilities may be used by shooting lasers at your bubbles. Using Unity and C#, this game has realistic graphics in a magical and mysterious atmosphere.",
            <div style={{ display: 'flex', justifyContent: 'center' }}><Suspense><YouTubeEmbed url="https://www.youtube.com/watch?v=wC590OBr5Qk" width={325} height={220}/></Suspense></div>,
            screen0blb,
            screen1blb,
            screen2blb,
            "Realistic 3D art using PBR materials&FPS controls, shooting, platforming&Different monsters with different behavior and loots&Puzzles to access new rooms&Potion crafting&Different bosses&Unlockable power-ups",
            "Unity: Game engine used&C#: Main programming language&Blender: Main 3D art program&Inkscape: Main 2D art program&Ableton: main sound / music program&Feel and DOTween : Assets that greatly speed up the process of creating animations and SFX&Visual Shader Graph: Main VFX tool in unity&URP : Main rendering Pipeline",
            "As the main developer, I designed, programmed the game mechanics, created the art,  mixed the sounds, produced the music."
        ] ]

function DescToList({str})
{
    const list = str.split("&").map((element, index) => { return <li key={index}>{element}</li>  });
    return (<ul> 
                {list}
            </ul>);
}
  
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " arrow"}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      >
                <IoIosArrowForward style={{ fill: "url(#blue-gradient)" }} />
    </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className + " arrow"}
        style={{ ...style, display: "block"}}
        onClick={onClick}>
            <IoIosArrowBack style={{ fill: "url(#blue-gradient)" }} />
        </div>
    );
  }
  
function Project({index, virtuoso, updateIntersection})
{
    const ref = useRef(null);
    useEffect(() => {
        if(index === -1)
            return;
        const observer = new IntersectionObserver(([ entry ]) => {
            updateIntersection(index, entry.isIntersecting);
        }, {
        root: null,
        })

        observer.observe(ref.current);
        return () => { observer.disconnect(); }
    });

    if(index === -1)
        return <></>;
    const settings = {
        className: "slides ",
        accessibility:true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll:1,
        swipeToSlide: true,
        infinite: true,
        nextArrow:<SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        mobileFirst: true,
        
        responsive: [{
            settings: {
                breakpoint: 2048,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll:1,
                }
            },
                breakpoint: 1025,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                },
            }
            ],
        speed: 500
      };
    return (
        <div ref={ref} id={"project"+index} style={{width : "100%"}}>
            <svg width="0" height="0">
                <linearGradient id="blue-gradient" x1="100%" y1="50%" x2="0%" y2="50%">
                    <stop stopColor="#7a6ded" offset="0%">
                    <animate attributeName="stop-color"
                        values="red;purple;blue;green;yellow;orange;red;" dur="20s" repeatCount="indefinite">
                    </animate>
                    </stop>
                    <stop stopColor="#591885" offset="100%">
                        
                    <animate attributeName="stop-color"
                        values="purple;blue;green;yellow;orange;red;purple;" dur="20s" repeatCount="indefinite">
                    </animate>
                    </stop>
                </linearGradient>
            </svg>
            <div className="project_intro">
                <div className='project_presentation'>
                    <div className='project_name'>
                    <a className="clickable" href={ProjectDescription[index][2]}><img src={ProjectDescription[index][0]}></img></a>
                            <div className='project_info'>
                                <h2>{ProjectDescription[index][1]}</h2>
                                <a className="clickable" href={ProjectDescription[index][2]}>{ProjectDescription[index][3]}</a>
                            </div>
                    </div>
                    <div className='project_name'>
                        <p>{ProjectDescription[index][4]}</p>
                    </div>
                </div>
            </div>
            <div className="project_images">
                <div className="slider-container">
                    <Slider {...settings}>
                         <div>{ProjectDescription[index][5]}</div>
                         <div><img className='slide-img' src={ProjectDescription[index][6]} /></div>
                         <div><img className='slide-img' src={ProjectDescription[index][7]} /></div>
                         <div><img className='slide-img' src={ProjectDescription[index][8]} /></div>
                    </ Slider>
                </div>
            </div>
            <div  className="project_descs">
                <div className='project_desc'>
                    <h3>Key Features</h3>
                    <DescToList str={ProjectDescription[index][9]}/>
                </div>
                <div className='project_desc'>
                    <h3>Used Technologies</h3>
                    <DescToList str={ProjectDescription[index][10]}/>
                </div>
                <div className='project_desc'>
                    <h3>Role and Contribution</h3>
                    <p>{ProjectDescription[index][11]}</p>
                </div>
            </div>
            <div className="project_end"></div>
        </div>
    );
}


function FooterProjects({virtuoso, pIndex, setPindex})
{
    return (
    <div className="footer_projects">
        {ProjectDescription.map((elt, i) => <img src={elt[0]} key={i} className={i === pIndex ? "current_project" : undefined} onClick={() => 
            {
                                 
                virtuoso.current.scrollToIndex({
                index: i,
                align: "start",
                behavior: "smooth"
                });
               return;
                }}></img>)}
    </div>);
}
function Projects() 
{
    const [pIndex, setPIndex] = useState(0);
    const [intersections, setIntersection] = useState([true, false, false, false, false]);
    const virtuoso = useRef(null);
    const location = useLocation();
    const [startIndex, setStartIndex] = useState(-1);
    function updateIntersection(index, value)
    {
        if(intersections[index] !== value)
        {
            var newIntersection = [...intersections];
            newIntersection[index] = value;
            var lastTrueIndex = 0;
            for(var i = 0; i < newIntersection.length; i++)
            {
                if(newIntersection[i] === true)
                    lastTrueIndex = i;
            }
            setPIndex(lastTrueIndex);
            setIntersection(newIntersection);
        }
    }
   useEffect(() => 
        {
            if(location && location.state)
            {
                /*
              virtuoso.current.scrollToIndex({
                index: parseInt(location.state.from),
                align: "start",
                behavior: "smooth"
              }); */
              setStartIndex(parseInt(location.state.from));
            }
            else
                setStartIndex(0);
        }, [location]); 
    return (
    <>
    <div className="project_page content content-with-footer" style={{ height: '100%' }} >
            <Virtuoso
            useWindowScroll
            overscan={600}
            style={{ height: '100%' }}
            initialTopMostItemIndex={startIndex}
            ref={virtuoso}
            totalCount={ProjectDescription.length}
            itemContent={index => <Project key={index} index={index} virtuoso={virtuoso} updateIntersection={updateIntersection}/>}
            />
        <FooterProjects virtuoso={virtuoso} pIndex={pIndex} setPindex={setPIndex}/>
    </div>
    </>);
}

export default Projects;

