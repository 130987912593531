import './Skills.css';
import { SiUnity, SiCsharp, SiVisualstudiocode, SiAndroid, SiUnrealengine, SiCplusplus, SiPython, SiGit, 
         SiInkscape, SiOpengl, SiBlender, SiTrello, SiGoogleads   } from "react-icons/si";
import { FaJava } from "react-icons/fa";


function Skills() 
{
    return (
        <>
        <div className='skills content'>
            <a href="https://unity.com/"><SiUnity style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://dotnet.microsoft.com/en-us/languages/csharp"><SiCsharp style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://code.visualstudio.com/"><SiVisualstudiocode style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://www.android.com/"><SiAndroid style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://www.unrealengine.com/"><SiUnrealengine style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://en.wikipedia.org/wiki/C%2B%2B"><SiCplusplus style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://www.python.org/"><SiPython style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://git-scm.com/"><SiGit style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://www.opengl.org/"><SiOpengl style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://inkscape.org/"><SiInkscape style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://www.blender.org/"><SiBlender style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://trello.com"><SiTrello style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://ads.google.com/"><SiGoogleads style={{ fill: "url(#icon-gradient)" }} /></a>
            <a href="https://www.java.com/"><FaJava style={{ fill: "url(#icon-gradient2)" }} /></a>
            <a href="https://gameanalytics.com/">
                <svg height="64" preserveAspectRatio="xMidYMid" viewBox="0 0 256 187" width="64" xmlns="http://www.w3.org/2000/svg">
                    <path d="m121.474 45.3190339c5.002-9.665 8.723-21.757 11.188-36.22899996-58.339-22.91000004-129.115-5.092-132.662 83.11299996.947 81.9010001 66.864 111.8840001 139.855 83.3800001l35.962-126.0020001 34.631 131.3300001c15.912 2.698 31.174 2.986 45.552 0l-55.942-179.01300006c-16.693-2.53-31.966-2.53-45.818 0l-23.709 74.32199996c7.959 2.397 17.192 3.257 25.307 4.795h-68.995c-2.206 12.485-2.056 24.2490001 0 35.4300001h30.902l-8.792 27.704c-36.495 9.49-62.468-3.796-62.867-52.7450001 1.165-46.618 26.023-63.613 75.388-46.085" fill="url(#icon-gradient3)"/>
                </svg>
            </a>
            
        </div>
        <svg stylewidth="0" height="0">
                <radialGradient id="icon-gradient" cx="106.57" cy="90.226" r="50" gradientTransform="matrix(2.2959 -1.3255 1.5786 2.7343 -280.54 -23.554)" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#f5f5f5" offset="0"/>
                    <stop stopColor="#6f6f6f" offset=".75"/>
                    <stop stopColor="#f5f5f5" offset="1"/>
                </radialGradient>
                <radialGradient id="icon-gradient2" cx="56.57" cy="10.226" r="300" gradientTransform="matrix(2.2959 -1.3255 1.5786 2.7343 -280.54 -23.554)" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#f5f5f5" offset="0"/>
                    <stop stopColor="#6f6f6f" offset=".75"/>
                    <stop stopColor="#f5f5f5" offset="1"/>
                </radialGradient>
                <radialGradient id="icon-gradient3" cx="0" cy="0" r="300" gradientTransform="matrix(2.2959 -1.3255 1.5786 2.7343 -280.54 -23.554)" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#f5f5f5" offset="0"/>
                    <stop stopColor="#6f6f6f" offset=".75"/>
                    <stop stopColor="#f5f5f5" offset="1"/>
                </radialGradient>
            </svg>
        </>
    );
}

export default Skills;